export const getSocialUrl = (handle: string, network: string) => {
    if (handle) {
        if (network === 'facebook') {
            return `https://facebook.com/${handle.replace('@', '')}`;
        }

        if (network === 'linkedin') {
            return `https://linkedin.com/in/${handle.replace('@', '')}`;
        }

        return `https://twitter.com/${handle.replace('@', '')}`;
    } else {
        return '';
    }
};

export const isLinkExternal = (link: { type: string }) =>
    link?.type === 'external' || link?.type === 'video' || link?.type === 'download' || link?.type === 'social';

export const getCurrentUrlWithParams = (localeCode: any) => {
    return getCurrentUrlWithParamsFromLocation(new URL(window.location.toString()), localeCode);
};

export const getCurrentUrlWithParamsFromLocation = (location: URL, localeCode: string) => {
    const copy = new URL(location.toString());

    copy.searchParams.set('lang', localeCode);

    if (copy.searchParams.has('center') && copy.searchParams.get('center') === 'global') {
        copy.searchParams.delete('center');
    }

    return copy.toString();
};
