<template>
    <GlobalLayout />
</template>

<script setup lang="ts">
import MeiliSearch from 'meilisearch';

    const runtimeConfig = useRuntimeConfig()
    const { host, key } = runtimeConfig.public.meili

    const client = new MeiliSearch({ host, apiKey: key });

    provide('meilisearch-client', client);
</script>

<style lang="scss">
// Not sure why but an scss error happens without something here
body {
}
</style>
