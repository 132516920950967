<template>
    <component :is="icon" :class="CSSClasses" />
</template>

<script setup>
const props = defineProps({
    // FIXME: throwing a vue warning
    name: {
        type: String,
        required: true,
        validator: (value) => {
            return [
                'arrow-down',
                'arrow-forward',
                'arrow-forward-circled',
                'arrow-backward-circled',
                'arrow-left',
                'arrow-right',
                'calendar',
                'cancel',
                'close',
                'download',
                'email',
                'facebook',
                'filters',
                'hamburger',
                'language',
                'linkedin',
                'location',
                'more',
                'search',
                'twitter',
                'video',
                'youtube',
                'link',
                'print',
                'pin',
                'share',
                'center',
                'logo-lettermark',
                'location',
                'telegram',
                'clear-lg',
                'clear',
                'diwan',
                'instagram',
            ].includes(value);
        },
    },
    color: {
        type: String,
        default: '',
    },
});

const icon = defineAsyncComponent({
    loader: () =>
        import(`../../assets/svg/${props.name}.svg`).catch((error) => {
            console.error(`Failed to load svg ${props.name}:`, error);
        }),
});

const direction = inject('direction');

const CSSClasses = computed(() => [
    props.color ? props.color : null,
    `direction-${direction.value}`,
    ['arrow-forward', 'arrow-forward-circled', 'arrow-backward-circled', 'arrow-left', 'arrow-right'].includes(
        props.name,
    ) && 'is-arrow',
]);
</script>

<style lang="scss" scoped>
.direction-rtl.is-arrow {
    transform: rotate(180deg);
}
</style>
